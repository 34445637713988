/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import p1 from 'assests/Photos/Clubs/LitreryClub/MadAds/1.png';
// import p2 from 'assests/Photos/Clubs/LitreryClub/MadAds/2.png';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const MadAds = () => {

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/MadAds/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/MadAds/2.webp`;

  
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        
        <br />
        <Typography variant='h5' fontWeight={600}>
        Mad Ads
        </Typography>
        <Typography fontSize = "x-small">
          Class  9 & 10 - 15.07.2022
        </Typography>
        <br/>
        <Typography variant={'subtitle1'} align={'justify'}>
        Language Association organized an activity on the topic ‘Mad Ads’ for classes 9&10 on 15 July 2022. It was a team event and each team was asked to advertise products of their choice. The performance showcased their in depth understanding of the products. The advertisements were presented with creativity and perfection, captured the attention of the audience
        </Typography>
        <br/>
        <Typography align='center' fontWeight={600}>
        “Creativity comes from a conflict of Ideas.”
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 300,
            }}
          />
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 300,
            }}
          />
        </Box>
        
       
        
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default MadAds;
