import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import MadAds from './MadAds';
import StoryNaration from './StoryNaration';
import SideBar1 from './SideBar1';
import { Sidebar2023 } from 'views/Literaryclub2023/components';

const LiteraryClub= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
 
         {/* <Container maxWidth={0.88} paddingTop={'0 !important'}  >
         
            
            
        </Container> */}
        <Container >
        <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
            <Form />
           
            </Grid>
            <Grid item xs={12} md={3}>
            
                <Box marginBottom={4}>
                  <SideBar1/>
                </Box>

                <Box marginBottom={4}>
                  <Sidebar2023/>
                </Box>
        
              {/* <SidebarNewsletter /> */}

            </Grid>
          </Grid>

        </Container>
    </Main>
  );
};

export default LiteraryClub;
